import React, { useState, useEffect } from 'react';

import Layout from "../components/layout"
import { SEO } from '../components/seo';

const Calculator = () => {
  const [dezena, setDezena] = useState(0);
  const [unidade, setUnidade] = useState(0);
  const [multiplicador, setMultiplicador] = useState(1);
  const [tolerancia, setTolerancia] = useState(1);
  const [resultado, setResultado] = useState('');
  const [isStandard, setIsStandard] = useState(true);
  const [isHelpVisible, setIsHelpVisible] = useState(false);


  const isStandardE12Value = (resistance) => {
    const E12BaseValues = [10, 12, 15, 18, 22, 27, 33, 39, 47, 56, 68, 82];
    for (let i = 0; i < 10; i++) {
      const normalizedValue = resistance / Math.pow(10, i);
      if (E12BaseValues.includes(Math.round(normalizedValue))) {
        return true;
      }
    }
    return false;
  };

  const calculateResistance = () => {
    const resistance = (parseInt(dezena, 10) * 10 + parseInt(unidade, 10)) * Math.pow(10, parseInt(multiplicador, 10));
    
    
    // Lista de valores da série E12
    const E12Values = [10, 12, 15, 18, 22, 27, 33, 39, 47, 56, 68, 82];
    
    // Verificar se a resistência está dentro da linha E12
    let inLine = false;
    let tempResistance = resistance;
    while (tempResistance >= 100) {
      tempResistance /= 10;
    }
    inLine = E12Values.includes(Math.round(tempResistance));
    
    // Adicionar um símbolo de alerta se estiver fora da linha
    let alertSymbol = inLine ? '' : ' ⚠️';
    
    // Verifica para exibição em kilo, Mega ou Giga ohms
    let displayValue = resistance >= 1e9 ? `${resistance / 1e9}G` : 
                       resistance >= 1e6 ? `${resistance / 1e6}M` : 
                       resistance >= 1e3 ? `${resistance / 1e3}k` : resistance;
  
    setResultado(`O resistor tem ${displayValue}Ω  com uma tolerância de +/- ${tolerancia}%${alertSymbol}`);
  };
  
  
  useEffect(() => {
    // Verifica se estamos no ambiente de navegador
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-SE4NY559V3');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
      };
    }

  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render

  
  

  return (
    <>
    <Layout />
    <div className='p-sm absolute right-10 top-32 w-128 h-auto rounded-xl bg-gradient-to-b from-white to-transparent bg-opacity-70'
      onMouseEnter={() => setIsHelpVisible(true)}
      onMouseLeave={() => setIsHelpVisible(false)}
    >
      <h2 className='text-orange-700'>{isHelpVisible ? 'Calculadora de resistores' : 'Está com dúvidas?'}</h2>
      <p 
        className={`text-gray-900 text-justify transition-all ease-in duration-300 ${isHelpVisible ? 'h-144 opacity-100' : 'h-0 opacity-0'}`}
      >
        Calculadora de resistores de 4 faixas. A tolerância fica à direita.<br />
        Eventualmente, alguns valores não existem e nesse caso, a calculadora tentará identificar o valor fora
        de padrão. Porém, a avaliação pode falhar em considerar um valor inválido, mas na maioria quase absoluta 
        dos casos, vai funcionar bem.
      </p>
    </div>

    {/* <h1 className="text-2xl mb-4">Calculadora de Resistor de 4 Faixas</h1> */}
    <div className="relative p-4 top-96 flex flex-row w-[42rem] bg-black bg-opacity-60 rounded-2xl h-40 p-sm ring-1 ring-orange-500">
      <select onChange={(e) => setDezena(e.target.value)} aria-label='1' className=' absolute top-6 left-6 h-12 rounded-md bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24 font-sans'>
        <option value="1" style={{backgroundColor: 'brown', color: 'white'}}>marrom</option>
        <option value="2" style={{backgroundColor: 'red', color: 'black'}}>vermelho</option>
        <option value="3" style={{backgroundColor: 'orange', color: 'black'}}>laranja</option>
        <option value="4" style={{backgroundColor: 'yellow', color: 'black'}}>amarelo</option>
        <option value="5" style={{backgroundColor: 'green', color: 'white'}}>verde</option>
        <option value="6" style={{backgroundColor: 'blue', color: 'white'}}>azul</option>
        <option value="7" style={{backgroundColor: 'purple', color: 'white'}}>roxo</option>
        <option value="8" style={{backgroundColor: 'gray', color: 'white'}}>cinza</option>
        <option value="9" style={{backgroundColor: 'white', color: 'black'}}>branco</option>
        {/* ... */}
      </select>

      <select onChange={(e) => setUnidade(e.target.value)} aria-label='2' className='absolute top-6 left-36 h-12 rounded-md bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24 font-sans'>
        <option value="0" style={{backgroundColor: 'black', color: 'white'}}>preto</option>
        <option value="1" style={{backgroundColor: 'brown', color: 'white'}}>marrom</option>
        <option value="2" style={{backgroundColor: 'red', color: 'black'}}>vermelho</option>
        <option value="3" style={{backgroundColor: 'orange', color: 'black'}}>laranja</option>
        <option value="4" style={{backgroundColor: 'yellow', color: 'black'}}>amarelo</option>
        <option value="5" style={{backgroundColor: 'green', color: 'white'}}>verde</option>
        <option value="6" style={{backgroundColor: 'blue', color: 'white'}}>azul</option>
        <option value="7" style={{backgroundColor: 'purple', color: 'white'}}>roxo</option>
        <option value="8" style={{backgroundColor: 'gray', color: 'white'}}>cinza</option>
        <option value="9" style={{backgroundColor: 'white', color: 'black'}}>branco</option>
        {/* ... */}
      </select>

      <select onChange={(e) => setMultiplicador(e.target.value)} aria-label='3' className='absolute top-6 left-64 ml-2 h-12 rounded-md bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24 font-sans'>
        <option value="0" style={{backgroundColor: 'black', color: 'white'}}>preto</option>
        <option value="1" style={{backgroundColor: 'brown', color: 'white'}}>marrom</option>
        <option value="2" style={{backgroundColor: 'red', color: 'black'}}>vermelho</option>
        <option value="3" style={{backgroundColor: 'orange', color: 'black'}}>laranja</option>
        <option value="4" style={{backgroundColor: 'yellow', color: 'black'}}>amarelo</option>
        <option value="5" style={{backgroundColor: 'green', color: 'white'}}>verde</option>
        <option value="6" style={{backgroundColor: 'blue', color: 'white'}}>azul</option>
        <option value="7" style={{backgroundColor: 'purple', color: 'white'}}>roxo</option>
        <option value="8" style={{backgroundColor: 'gray', color: 'white'}}>cinza</option>
        <option value="9" style={{backgroundColor: 'white', color: 'black'}}>branco</option>
        <option value="9" style={{backgroundColor: 'gold', color: 'black'}}>dourado</option>
        <option value="8" style={{backgroundColor: 'silver', color: 'black'}}>prata</option>
        
        {/* ... */}
      </select>

      <select onChange={(e) => setTolerancia(e.target.value)} aria-label='4' className='absolute top-6 left-96  h-12 rounded-md bg-black bg-opacity-50 ring-1 ring-orange-500 ring-opacity-70 w-24 font-sans'>
        <option value="1" style={{backgroundColor: 'brown', color: 'white'}}>marrom</option>
        <option value="2" style={{backgroundColor: 'red', color: 'black'}}>vermelho</option>
        <option value="0.5" style={{backgroundColor: 'green', color: 'white'}}>verde</option>
        <option value="0.25" style={{backgroundColor: 'blue', color: 'white'}}>azul</option>
        <option value="0.1" style={{backgroundColor: 'purple', color: 'white'}}>roxo</option>
        <option value="0.05" style={{backgroundColor: 'gray', color: 'white'}}>cinza</option>
        <option value="5" style={{backgroundColor: 'gold', color: 'black'}}>dourado</option>
        <option value="10" style={{backgroundColor: 'silver', color: 'black'}}>prata</option>
        {/* ... */}
      </select>

      <button onClick={calculateResistance} className="absolute px-sm top-6 left-128 -ml-1  h-12 bg-orange-500 hover:bg-orange-700 text-black  font-bold font-sans rounded">
        Calcular
      </button>
      {resultado && <div className="absolute top-28 -ml-1 font-sans text-orange-100">
        {resultado}
        {!isStandard && <span role="img" aria-label="Alerta">⚠️</span>}
      </div>}
    </div>
    </>
  );
};

export default Calculator;

export const Head = ({ data }) => (
  <SEO
    title={"Resistor calculator"}
    description={"Calculadora de resistores"}
    pathname={"resistor/"}
    children={""}
    pageType={"Article"}
    publishedAt={"2023-09-01"}
    thumbnail={"/manualdomaker-toolbox.png"}
  />
);